import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import PlainTextLayout from './PlainTextLayout';

const PaimaManifestoSection = () => {
  const { t } = useTranslation();

  return (
    <PlainTextLayout
      title={t('Paima Studios Manifesto')}
      id="manifesto"
      content={[
        t(
          "The world of blockchain gaming today is not focused on building innovative Web3 gaming, but is instead stuck on 'duct-taping' NFTs and tokens onto classical Web2 gaming stacks (aka. Web2.5 gaming)."
        ),
        t(
          "These Web2.5 gaming projects incorporate small elements of blockchain technology, however are still fully dependent on the game development studio to run centralized private servers for the game to function at all. If the company goes out of business, an employee goes rogue, or they are explicitly hacked, then the entire game can go offline and all of the player data can disappear potentially forever. What's worse, on top of inheriting all of the negative tradeoffs of the Web2 stack, Web2.5 gaming only offers a sub-par gaming experience and little upside for the everyday player."
        ),
        t(
          'The truth is that Web2.5 gaming is about as technologically innovative as centralized exchanges, leaving both devs and players uninterested overall. This has been proven by the lack of significant mainstream adoption of Web2.5 games in the previous years despite numerous projects pushing in this direction. In fact, looking at current iterations of the Web2.5 gaming model, these games primarily only attract speculators, leading to extremely short lifespans for the projects themselves with no longevity to build something that will truly last for years to come.'
        ),
        t(
          'At Paima Studios our mission is to move the blockchain gaming industry from centralized Web2.5 gaming, to a new era of Trustless Web3 Gaming. Just like how DeFi was built by an ecosystem focused on shipping trustless financial primitives, we believe that the future of blockchain gaming will be built by an ecosystem focused on shipping trustless gaming primitives. Trustless Web3 Gaming is the future of blockchain gaming.'
        ),
        t(
          'To bring this vision into reality, we have spent numerous months on research in discovering novel trustless gaming primitives (with significant inspiration from L2/rollup technologies) which has culminated in the development of Paima Engine.'
        ),
        t(
          'Paima Engine is our answer to the current state of blockchain gaming. It is the very first Trustless Web3 Gaming engine, which enables game developers to build their own blockchain games as full-fledged L2s (Layer 2s).'
        ),
        t(
          'In other words, Paima Engine allows game devs to implement their games in a language they are familiar with (Typescript/Javascript), while hiding away the vast majority of complexity of blockchain integration, yet still utilizing the most bleeding-edge technology available in the blockchain gaming industry.'
        ),
        t(
          'Games built with Paima Engine can be understood as trustless & open blockchain protocols which take take advantage of the latest in L2 scaling technology. Furthermore, by going in this completely novel direction, Paima Engine is also pioneering newly discovered trustless gaming primitives which have never been possible before in the Web2.5 model, such as Stateful NFTs.'
        ),
        t(
          'At Paima Studios, we believe innovation is a core requirement for long-term success of any new technology. Paima Engine is at the forefront of Trustless Web3 Gaming and will provide developers (both small indie teams, and large studios) the ability to jump into the new era of blockchain gaming with as little friction as possible.'
        ),
        t(
          'We are excited to be pushing the boundaries of blockchain gaming with all of you, and look forward to seeing just how far Trustless Web3 Gaming will go in the years to come.'
        )
      ]}
      bottomNote={t('Paima Team')}
    />
  );
};

export default PaimaManifestoSection;
