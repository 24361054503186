import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import PaimaManifestoSection from '../components/v2/PaimaManifestoSection';

const Manifesto = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Manifesto')}>
      <Header showComingSoonCountdown={false} redirectToBuyNft />
      <CookieConsentBanner />
      <PaimaManifestoSection />
      <Footer />
    </Layout>
  );
};

export default Manifesto;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
